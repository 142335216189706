/* eslint-disable no-param-reassign */

import Axios from 'axios';
import { configure } from 'axios-hooks';

const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.request.use(
    async (config) => {
        const token = 'Bearer ' + localStorage.getItem('token');
        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: token,
            };
        }
        return config;
    },
    (error) => Promise.reject(error),
);

const defaultOptions = {
    useCache: false,
};

configure({ axios, defaultOptions });
