import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Password } from './components';
import { Api } from '../../common/Api';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
}));

const Account = props => {
    const classes = useStyles();
    const api = new Api();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        password: '',
        confirm: '',
    });

    const handleUpdate = event => {
        setLoading(true);
        if (values.password === values.confirm) {
            api.updatePassword(values.password)
                .then(props.history.push('/dashboard'))
                .catch(err => handleErr(err));
        } else {
            console.log('Password mismatch');
        }
    };

    const handleErr = err => {
        if (err.status === 401) {
            props.history.push('/login?ref=' + props.location.pathname);
        }
    };
    if (loading) {
        return (
            <div className={classes.rootLoad}>
                <LinearProgress />
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                <Password
                    values={values}
                    setValues={setValues}
                    handlePassword={handleUpdate}
                />
            </div>
        );
    }
};
Account.propTypes = {
    props: PropTypes.object,
};

export default withRouter(Account);
