import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import MaterialTable from 'material-table';

const dateFormat = 'YYYY-MM-DD hh:mm a';

const HistoryCard = props => {
    return (
        <MaterialTable
            title="Deployment History"
            columns={[
                { title: 'AWS ID', field: 'awsID' },
                {
                    title: 'Cost',
                    field: 'totalCost',
                    render: row =>
                        '~$' + Number.parseFloat(row.totalCost).toFixed(4),
                },
                {
                    title: 'Started At',
                    field: 'startedAt',
                    render: row =>
                        moment
                            .utc(row.startedAt)
                            .local()
                            .format(dateFormat),
                },
                {
                    title: 'Stopped At',
                    field: 'stoppedAt',
                    render: row =>
                        moment
                            .utc(row.stoppedAt)
                            .local()
                            .format(dateFormat),
                },
            ]}
            data={props.changes}
            options={{
                pageSize: 10,
            }}
        />
    );
};

HistoryCard.propTypes = {
    changes: PropTypes.array.isRequired,
};

export default HistoryCard;
