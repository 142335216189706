import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button,
    Typography,
    Grid,
    Link,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    cardAttr: {
        padding: theme.spacing(2),
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const JenkinsCard = props => {
    const deploy = props.deploy;

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader title="Jenkins Job Configuration" />
            <Divider />
            <CardContent>
                <div className={classes.cardAttr}>
                    <Typography component="h6" variant="h6">
                        {deploy.deployName ? deploy.deployName : ' --- '}
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                        Jenkins Job
                    </Typography>
                </div>
                <div className={classes.cardAttr}>
                    <Typography component="h6" variant="h6">
                        {deploy.jenkinsURL ? (
                            <Link
                                href={deploy.jenkinsURL}
                                className={classes.link}
                                target="_blank"
                            >
                                {deploy.jenkinsURL}
                            </Link>
                        ) : (
                            ' --- '
                        )}
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                        Jenkins Job URL
                    </Typography>
                </div>
                <Grid container alignItems="center" spacing={4}>
                    <Grid item lg={6} md={6} xl={6} xs={6}>
                        <div className={classes.cardAttr}>
                            <Typography
                                component="h6"
                                variant="h6"
                                style={{ textTransform: 'uppercase' }}
                            >
                                {deploy.jenkinsState}
                            </Typography>
                            <Typography
                                variant="overline"
                                color="textSecondary"
                            >
                                Jenkins Job State
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={6}>
                        <div className={classes.cardAttr}>
                            <Typography component="h6" variant="h6">
                                {deploy.jenkinsLastBuild
                                    ? deploy.jenkinsLastBuild
                                    : ' --- '}
                            </Typography>
                            <Typography
                                variant="overline"
                                color="textSecondary"
                            >
                                Last Build Result
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
            <CardActions>
                <Grid container justify="space-between">
                    <Grid className={classes.statsItem} item>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={deploy.jenkinsState !== 'notcreated'}
                            onClick={props.handleCreate}
                        >
                            Create Job
                        </Button>
                    </Grid>
                    <Grid className={classes.statsItem} item>
                        <Button
                            color="primary"
                            disabled={deploy.jenkinsState !== 'created'}
                            onClick={props.handleBuild}
                        >
                            Build Job
                        </Button>
                    </Grid>
                    <Grid className={classes.statsItem} item>
                        <Button
                            color="secondary"
                            variant="outlined"
                            disabled={deploy.jenkinsState === 'notcreated'}
                            onClick={props.handleDelete}
                        >
                            Delete Job
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

JenkinsCard.propTypes = {
    deploy: PropTypes.object.isRequired,
    handleCreate: PropTypes.func,
    handleBuild: PropTypes.func,
    handleDelete: PropTypes.func,
    handleScroll: PropTypes.func,
};

export default JenkinsCard;
