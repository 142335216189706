import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button,
    Typography,
    Grid,
    Link,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    cardAttr: {
        padding: theme.spacing(2),
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const AWSCard = props => {
    const deploy = props.deploy;

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <form>
                <CardHeader title="AWS Configuration" />
                <Divider />
                <CardContent>
                    <div className={classes.cardAttr}>
                        <Typography component="h6" variant="h6">
                            {deploy.sslURL ? (
                                <Link
                                    href={deploy.sslURL}
                                    className={classes.link}
                                    target="_blank"
                                >
                                    {deploy.sslURL}
                                </Link>
                            ) : (
                                    <Link
                                        href={`https://${deploy.deployName}.envoyproxy.automatrix.com/`}
                                        className={classes.link}
                                        target="_blank"
                                    >
                                        {`https://${deploy.deployName}.envoyproxy.automatrix.com/`}
                                    </Link>
                                )}
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                            Secure URL
                        </Typography>
                    </div>
                    <div className={classes.cardAttr}>
                        <Typography component="h6" variant="h6">
                            {deploy.awsURL ? (
                                <Link
                                    href={'http://' + deploy.awsURL}
                                    className={classes.link}
                                    target="_blank"
                                >
                                    {deploy.awsURL}
                                </Link>
                            ) : (
                                    ' --- '
                                )}
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                            AWS URL
                        </Typography>
                    </div>
                    <Grid container alignItems="center" spacing={4}>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                            <div className={classes.cardAttr}>
                                <Typography
                                    component="h6"
                                    variant="h6"
                                    style={{ textTransform: 'uppercase' }}
                                >
                                    {deploy.awsState}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    AWS State
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={6}>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {deploy.awsID ? deploy.awsID : ' --- '}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    AWS Instance ID
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                    <Grid container justify="space-between">
                        <Grid className={classes.statsItem} item>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={deploy.awsState !== 'notcreated'}
                                onClick={props.handleCreate}
                            >
                                Create Instance
                            </Button>
                        </Grid>
                        <Grid className={classes.statsItem} item>
                            <Button
                                color="primary"
                                disabled={deploy.awsState !== 'running'}
                                onClick={props.handleSSL}
                            >
                                Enable SSL
                            </Button>
                        </Grid>
                        <Grid className={classes.statsItem} item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                disabled={deploy.awsState !== 'running'}
                                onClick={props.handleDelete}
                            >
                                Terminate Instance
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </form>
        </Card>
    );
};

AWSCard.propTypes = {
    deploy: PropTypes.object.isRequired,
    handleCreate: PropTypes.func,
    handleDelete: PropTypes.func,
    handleSSL: PropTypes.func,
};

export default AWSCard;
