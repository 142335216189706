import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Grid, LinearProgress, CardHeader, Divider } from '@material-ui/core';
import { ProjectCard, DeployCard } from './components';
import { Api } from '../../common/Api';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    rootLoad: {
        flexGrow: 1,
    },
    tableHead: {
        background: theme.palette.primary,
    },
}));

const Dashboard = props => {
    const classes = useStyles();

    const [dashboardData, setdashboardData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (dashboardData === null) {
            const api = new Api();
            api.getDashboard()
                .then(data => {
                    setdashboardData(data);
                    setLoading(false);
                })
                .catch(err => handleErr(err));
        }
    });
    const handleErr = err => {
        if (err.status === 401) {
            props.history.push('/login?ref=' + props.location.pathname);
        }
    };
    if (loading) {
        return (
            <div className={classes.rootLoad}>
                <LinearProgress />
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <CardHeader title="Projects" />
                    <Divider />
                    <Grid container className={classes.content} spacing={3}>
                        {dashboardData.projects.map(product => (
                            <Grid item key={product.id} lg={4} md={6} xs={12}>
                                <ProjectCard project={product} />
                            </Grid>
                        ))}
                    </Grid>
                    <CardHeader title="Deployments" />
                    <Divider />
                    <Grid container className={classes.content} spacing={3}>
                        {dashboardData.deploys.map(product => (
                            <Grid item key={product.id} lg={4} md={6} xs={12}>
                                <DeployCard deploy={product} />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        );
    }
};
Dashboard.propTypes = {
    props: PropTypes.object,
};
export default withRouter(Dashboard);
