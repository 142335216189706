import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Api } from '../../common/Api';
import {
    LinearProgress,
    CardHeader,
    Divider,
    TextField,
    CardContent,
    CardActions,
    Button,
    Card,
    Grid,
    Tabs,
    AppBar,
    Tab,
} from '@material-ui/core';
import { MsgSnackbar } from '../../components';
import TabPanel from '../../components/TabPanel/TabPanel';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    rootLoad: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    cardAttr: {
        padding: theme.spacing(1),
    },
}));

const EditProject = props => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState(null);
    const [showMsg, setShowMsg] = useState(false);
    const [error, setError] = useState(null);
    const [tab, setTab] = React.useState(0);
    const tabChange = (event, newValue) => {
        setTab(newValue);
    };
    useEffect(() => {
        const api = new Api();
        if (project === null) {
            api.getEditProject(props.match.params.id)
                .then(data => {
                    setProject(data);
                    setLoading(false);
                    setError(null);
                })
                .catch(err => {
                    if (err.status === 401) {
                        props.history.push(
                            '/login?ref=' + props.location.pathname,
                        );
                    }
                    setError(err.error);
                    setShowMsg(true);
                });
        }
    }, [project, props]);
    const handleChange = event => {
        const myName = event.target.name;
        const myValue = event.target.value;
        setProject(projectState => ({
            ...projectState,
            [myName]: myValue,
        }));
    };
    const handleErr = err => {
        if (err.status === 401) {
            props.history.push('/login?ref=' + props.location.pathname);
        }
        setError(err.error);
        setShowMsg(true);
        setLoading(false);
    };
    const handleSuccess = data => {
        setError(null);
        setShowMsg(true);
        setProject(null);
    };
    const handleUpdateProject = event => {
        setLoading(true);
        const api = new Api();
        api.updateProject(props.match.params.id, project)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    };

    if (loading || project === null) {
        return (
            <div className={classes.rootLoad}>
                <MsgSnackbar
                    open={showMsg}
                    setOpen={setShowMsg}
                    msg={error}
                ></MsgSnackbar>
                <LinearProgress />
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                <MsgSnackbar
                    open={showMsg}
                    setOpen={setShowMsg}
                    msg={error}
                ></MsgSnackbar>
                <div className={classes.content}>
                    <Card className={classes.card} elevation={3}>
                        <CardHeader title="Edit Project" />

                        <CardContent>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        label="Name"
                                        name="name"
                                        value={project.name}
                                    />
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        onChange={handleChange}
                                        fullWidth
                                        label="Image"
                                        name="image"
                                        value={project.image}
                                    />
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        onChange={handleChange}
                                        fullWidth
                                        label="AWS AMI ID"
                                        name="awsAmiID"
                                        value={project.awsAmiID}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                alignItems="center"
                                spacing={2}
                                className={classes.content}
                            >
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        label="AWS Key Name"
                                        name="awsKeyName"
                                        value={project.awsKeyName}
                                    />
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        onChange={handleChange}
                                        fullWidth
                                        label="Instance Type"
                                        name="awsInstance"
                                        value={project.awsInstance}
                                    />
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        onChange={handleChange}
                                        fullWidth
                                        label="Security Group"
                                        name="awsSecGroup"
                                        value={project.awsSecGroup}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                alignItems="center"
                                spacing={2}
                                className={classes.content}
                            >
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        label="AWS Region"
                                        name="awsRegion"
                                        value={project.awsRegion}
                                    />
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        onChange={handleChange}
                                        fullWidth
                                        label="AWS User"
                                        name="awsUser"
                                        value={project.awsUser}
                                    />
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        onChange={handleChange}
                                        type="number"
                                        fullWidth
                                        label="AWS Cost"
                                        name="awsCost"
                                        value={project.awsCost}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                alignItems="center"
                                spacing={2}
                                className={classes.content}
                            >
                                <Grid item md={6} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        label="Repo URL"
                                        name="repoURL"
                                        value={project.repoURL}
                                    />
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        onChange={handleChange}
                                        fullWidth
                                        label="Slack Hook"
                                        name="slackHook"
                                        value={project.slackHook}
                                    />
                                </Grid>
                            </Grid>
                            <div>
                                <AppBar
                                    className={classes.content}
                                    position="static"
                                >
                                    <Tabs value={tab} onChange={tabChange}>
                                        <Tab
                                            label="Jenkins Build"
                                            {...a11yProps(0)}
                                        />
                                        <Tab
                                            label="Development"
                                            {...a11yProps(1)}
                                        />
                                        <Tab
                                            label="Test-Dev"
                                            {...a11yProps(2)}
                                        />
                                        <Tab
                                            label="Staging"
                                            {...a11yProps(3)}
                                        />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={tab} index={0}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        multiline
                                        rowsMax="30"
                                        value={project.jenkinsBuild}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        label="Jenkins Build"
                                        name="jenkinsBuild"
                                    />
                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        multiline
                                        rowsMax="30"
                                        value={project.development}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        label="Development Setting"
                                        name="development"
                                    />
                                </TabPanel>
                                <TabPanel value={tab} index={2}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        multiline
                                        rowsMax="30"
                                        value={project['test-dev']}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        label="Test-Dev Setting"
                                        name="test-dev"
                                    />
                                </TabPanel>
                                <TabPanel value={tab} index={3}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        multiline
                                        rowsMax="30"
                                        value={project.staging}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        label="Staging Setting"
                                        name="staging"
                                    />
                                </TabPanel>
                            </div>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Grid container justify="space-between">
                                <Grid className={classes.statsItem} item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleUpdateProject}
                                    >
                                        Update Deployment
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </div>
            </div>
        );
    }
};

EditProject.propTypes = {
    props: PropTypes.object,
};

export default withRouter(EditProject);
