import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Typography,
    Grid,
    Link,
} from '@material-ui/core';

import moment from 'moment';

const dateFormat = 'YYYY-MM-DD hh:mm a';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    cardAttr: {
        padding: theme.spacing(1),
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const DetailCard = props => {
    const deploy = props.deploy;

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <form>
                <CardHeader title="Deployment Details" />
                <Divider />
                <CardContent>
                    <Grid container>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {deploy.projectName}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Project
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {deploy.createdBy
                                        ? deploy.createdBy
                                        : ' --- '}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Created By
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {deploy.createdAt
                                        ? moment
                                            .utc(deploy.createdAt)
                                            .local()
                                            .format(dateFormat)
                                        : ' --- '}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Creation Date
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {deploy.awsUser !== ''
                                        ? deploy.awsState === 'running'
                                            ? 'ACTIVE'
                                            : 'INACTIVE'
                                        : deploy.jenkinsState === 'created'
                                            ? 'ACTIVE'
                                            : 'INACTIVE'}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Status
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {deploy.updatedBy
                                        ? deploy.updatedBy
                                        : ' --- '}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Updated By
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {deploy.updatedAt
                                        ? moment
                                            .utc(deploy.updatedAt)
                                            .local()
                                            .format(dateFormat)
                                        : ' --- '}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Updated Date
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {deploy.branch}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Branch
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {deploy.sslURL ? (
                                        <Link
                                            href={deploy.sslURL}
                                            className={classes.link}
                                            target="_blank"
                                        >
                                            {deploy.sslURL}
                                        </Link>
                                    ) : (
                                            <Link
                                                href={`https://${deploy.deployName}.envoyproxy.automatrix.com/`}
                                                className={classes.link}
                                                target="_blank"
                                            >
                                                {`https://${deploy.deployName}.envoyproxy.automatrix.com/`}
                                            </Link>
                                        )}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    URL
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </form>
        </Card>
    );
};

DetailCard.propTypes = {
    deploy: PropTypes.object.isRequired,
};

export default DetailCard;
