import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    cardAttr: {
        padding: theme.spacing(2),
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

const InfoCard = props => {
    const { env, addCustom, keepAlive } = props;
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader title="Deployment Settings" />
            <Divider />
            <CardContent>
                <Grid container alignItems="center" spacing={2}>
                    <Grid container spacing={3} className={classes.cardAttr}>
                        <Grid item xs={4}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="env">
                                    Environment
                                </InputLabel>
                                <Select
                                    value={env}
                                    onChange={props.handleChangeEnv}
                                    inputProps={{
                                        name: 'environemnt',
                                        id: 'env',
                                    }}
                                >
                                    <MenuItem value={'development'}>
                                        Development
                                    </MenuItem>
                                    <MenuItem value={'test-dev'}>
                                        Test-Dev
                                    </MenuItem>
                                    <MenuItem value={'staging'}>
                                        Staging
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel className={classes.formControl}
                                control={<Switch checked={keepAlive} onChange={props.handleChangeKeep} name="keep" />}
                                label="Keep Instance Alive"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel className={classes.formControl}
                                control={<Switch checked={addCustom} onChange={props.handleChangeCustom} name="custom" />}
                                label="Use Remote Mongo"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.cardAttr}>
                    <TextField
                        id="outlined-multiline-flexible"
                        multiline
                        rowsMax="10"
                        value={props.setting}
                        className={classes.root}
                        onChange={props.handleChangeSetting}
                        fullWidth
                        margin="normal"
                        helperText="Application Settings"
                        variant="outlined"
                    />
                </div>
            </CardContent>
            <Divider />
            <CardActions>
                <Grid container justify="space-between">
                    <Grid className={classes.statsItem} item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={props.handleUpdate}
                        >
                            Update Deployment
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

InfoCard.propTypes = {
    setting: PropTypes.string.isRequired,
    tags: PropTypes.array,
    env: PropTypes.string,
    addCustom: PropTypes.bool,
    keepAlive: PropTypes.bool,
    setTags: PropTypes.func,
    handleUpdate: PropTypes.func,
    handleChangeSetting: PropTypes.func,
    handleChangeEnv: PropTypes.func,
    handleChangeCustom: PropTypes.func,
    handeChangeKeep: PropTypes.func,
};

export default InfoCard;
