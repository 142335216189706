import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CircularProgress,
    Typography,
} from '@material-ui/core';
import useAxios from 'axios-hooks';
import MaterialTable from 'material-table';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    refreshIcon: {
        position: 'absolute',
        top: 30,
        right: 10,
        zIndex: 1000,
    }
}));

const dateFormat = 'YYYY-MM-DD hh:mm a';

const LogCard = props => {
    const deploy = props.deploy;
    const [{ data, loading, error }] = useAxios(`/tasks/${deploy.id}/logs`)
    const classes = useStyles();
    if (loading) return (<CircularProgress />)
    if (error) return (
        <Card className={classes.root}>
            <CardHeader title="Container Logs" />
            <Typography component="h6" variant="h6">
                There was an error retreiving the logs
            </Typography>
        </Card>
    )
    return (
        <div className={classes.root}>
            <MaterialTable
                columns={[
                    {
                        title: 'Timestamp',
                        field: 'Timestamp',
                        render: row =>
                            moment
                                .utc(row.Timestamp)
                                .local()
                                .format(dateFormat),
                        width: '20%'
                    },
                    { title: 'Message', field: 'Message' },
                ]}
                data={data}
                title="Container Logs"
                options={{
                    pageSize: 20,
                    rowStyle: {
                        overflowWrap: 'break-word'
                    }
                }}
            />
        </div>
    );
};

LogCard.propTypes = {
    deploy: PropTypes.object.isRequired,
};

export default LogCard;
