import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
    },
    content: {
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        fontWeight: 700,
    },
    image: {
        width: '100%',
    },
    imageContainer: {
        height: 64,
        width: 64,
        margin: '0 auto',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
}));

const DeployCard = props => {
    const { className, ...rest } = props;
    const deploy = props.deploy;
    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            TOTAL
                        </Typography>
                        <Typography variant="h3">{deploy.count}</Typography>
                    </Grid>
                    <Grid item>
                        <div className={classes.imageContainer}>
                            <img
                                alt="Project"
                                className={classes.image}
                                src={'/images/lang/' + deploy.image}
                            />
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.difference}>
                    <Typography className={classes.caption} variant="caption">
                        running instances of <strong>{deploy.id}</strong>
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

DeployCard.propTypes = {
    className: PropTypes.string,
    deploy: PropTypes.object.isRequired,
};

export default DeployCard;
