import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button,
    Typography,
    Grid,
    Link,
    CircularProgress,
    IconButton,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import useAxios from 'axios-hooks';
import { MsgSnackbar } from '../../../../components';
import { Api } from '../../../../common/Api';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    cardAttr: {
        padding: theme.spacing(2),
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const AWSTaskCard = props => {
    const api = new Api();
    const deploy = props.deploy;
    const [task, setTask] = useState({});
    const [{ data, loading, error: errorTask }] = useAxios(`/tasks/${deploy.id}`)
    const [updating, setUpdating] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        if (!loading && data) {
            setTask(data);
        }
        if (!loading && errorTask) {
            setTask({});
        }
    }, [loading, data, errorTask]);
    const handleErr = err => {
        if (err.status === 401) {
            props.history.push('/login?ref=' + props.location.pathname);
        }
        setError(err.error);
        setShowMsg(true);
        setUpdating(false);
        props.handleRefresh()
    };
    const handleSuccess = data => {
        props.handleRefresh()
    };
    const handleCreateTask = event => {
        setUpdating(true)
        api.createTaskDeploy(deploy.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    }
    const handleDeleteTask = event => {
        setUpdating(true)
        api.deleteTaskDeploy(deploy.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    }
    const handleEnableTaskSSL = event => {
        setUpdating(true)
        api.enableTaskSSL(deploy.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    }

    const classes = useStyles();
    if (loading) return (<CircularProgress />)
    return (
        <Card className={classes.root}>
            <MsgSnackbar
                open={showMsg}
                setOpen={setShowMsg}
                msg={error}
            ></MsgSnackbar>
            <form>
                <CardHeader title="AWS Task" />
                <Divider />
                <CardContent>
                    <div className={classes.cardAttr}>
                        <Typography component="h6" variant="h6">
                            {deploy.sslURL ? (
                                <Link
                                    href={deploy.sslURL}
                                    className={classes.link}
                                    target="_blank"
                                >
                                    {deploy.sslURL}
                                </Link>
                            ) : (
                                <Link
                                    href={`https://${deploy.deployName}.envoyproxy.automatrix.com/`}
                                    className={classes.link}
                                    target="_blank"
                                >
                                    {`https://${deploy.deployName}.envoyproxy.automatrix.com/`}
                                </Link>
                            )}
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                            Secure URL
                        </Typography>
                    </div>
                    <div className={classes.cardAttr}>
                        <Typography component="h6" variant="h6">
                            {task.publicDns ? (
                                <Link
                                    href={'http://' + task.publicDns}
                                    className={classes.link}
                                    target="_blank"
                                >
                                    {task.publicDns}
                                </Link>
                            ) : (
                                ' --- '
                            )}
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                            AWS URL
                        </Typography>
                    </div>

                    <div className={classes.cardAttr}>
                        <Typography component="h6" variant="h6">
                            {deploy.awsID ?? ' --- '}
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                            AWS Task ID
                        </Typography>
                    </div>
                    <div className={classes.cardAttr}>
                        <Typography
                            component="h6"
                            variant="h6"
                            style={{ textTransform: 'uppercase' }}
                        >
                            {task.status ?? ' --- '}
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                            AWS State
                        </Typography>
                    </div>
                </CardContent>
                <Divider />
                <CardActions>
                    <Grid container justify="space-between">
                        <Grid className={classes.statsItem} item>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={(task.status === 'RUNNING' && deploy.awsState !== 'notcreated') || updating}
                                onClick={handleCreateTask}
                            >
                                Create Container
                            </Button>
                        </Grid>
                        <Grid className={classes.statsItem} item>
                            <Button
                                color="primary"
                                disabled={task.status !== 'RUNNING' || updating}
                                onClick={handleEnableTaskSSL}
                            >
                                Enable SSL
                            </Button>
                        </Grid>
                        <Grid className={classes.statsItem} item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                disabled={task.status !== 'RUNNING' || updating}
                                onClick={handleDeleteTask}
                            >
                                Delete Container
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </form>
        </Card>
    );
};

AWSTaskCard.propTypes = {
    deploy: PropTypes.object.isRequired,
    handleRefresh: PropTypes.func,
};

export default AWSTaskCard;
