import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Api } from '../../common/Api';
import MaterialTable from 'material-table';
import {
    Button,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD hh:mm a';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    rootLoad: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    filters: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

const Deployments = props => {
    const classes = useStyles();
    const api = new Api();
    const tableRef = useRef();
    const searchRef = useRef();
    const [first, setFirst] = useState(true);
    const [pageSize, setPagesize] = useState(20);
    const [getUser, setGetUser] = useState(true);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('');
    const [user, setUser] = useState('');

    useEffect(() => {
        if (getUser) {
            api.getUsers()
                .then(data => {
                    setGetUser(false);
                    setUsers(data);
                })
                .catch(err => {
                    if (err.status === 401) {
                        props.history.push('/login?ref=' + props.location.pathname);
                    }
                });
        }
    })
    const handleChangeSearch = event => {
        setSearch(event.target.value);
        tableRef.current && tableRef.current.onQueryChange();
        // searchRef.current && searchRef.current.focus();

    };
    const handleChangeStatus = event => {
        setStatus(event.target.value);
        tableRef.current && tableRef.current.onQueryChange();
    };
    const handleChangeUser = event => {
        setUser(event.target.value);
        tableRef.current && tableRef.current.onQueryChange();
    };
    const handleClearFilters = event => {
        setUser('');
        setStatus('');
        setSearch('');
        tableRef.current && tableRef.current.onQueryChange();
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <MaterialTable
                    tableRef={tableRef}
                    title="Deployments"
                    columns={[
                        {
                            title: 'Code Name',
                            field: 'deployName',
                            render: row => (
                                <RouterLink to={'/manage-deploy/' + row.id}>
                                    {row.deployName}
                                </RouterLink>
                            ),
                        },
                        {
                            title: 'Project',
                            field: 'projectName',
                            lookup: {
                                'dms-api': 'API',
                                'dms-web': 'WEB',
                                'crm-web': 'CRM-DEAL',
                                'dms-graphql': 'GRAPHQL',
                                'dms-desktop': 'DESKTOP',
                                'dms-mpi': 'MPI',
                                'dms-reports': 'REPORTS',
                                'dms-pro': 'DMS-PRO',
                            },
                        },
                        { title: 'Branch', field: 'branch' },
                        {
                            title: 'AWSState',
                            field: 'awsState',
                            lookup: {
                                running: 'RUNNING',
                                notcreated: 'NOTCREATED',
                            },
                        },
                        {
                            title: 'JenkinsState',
                            field: 'jenkinsState',
                            lookup: {
                                created: 'CREATED',
                                running: 'RUNNING',
                                notcreated: 'NOTCREATED',
                            },
                        },
                        {
                            title: 'URL',
                            field: 'awsURL',
                            render: row => (
                                <Link
                                    href={'http://' + row.awsURL}
                                    className={classes.link}
                                    target="_blank"
                                >
                                    {row.awsURL}
                                </Link>
                            ),
                        },
                        {
                            title: 'Cost',
                            field: 'totalCost',
                            render: row =>
                                '~$' +
                                Number.parseFloat(row.totalCost).toFixed(4),
                        },
                        {
                            title: 'Created By',
                            field: 'createdBy',
                        },
                        {
                            title: 'Created',
                            field: 'createdAt',
                            render: row =>
                                moment
                                    .utc(row.createdAt)
                                    .local()
                                    .format(dateFormat),
                        },
                        {
                            title: 'Updated By',
                            field: 'updatedBy',
                        },
                        {
                            title: 'Updated',
                            field: 'updatedAt',
                            render: row =>
                                moment
                                    .utc(row.updatedAt)
                                    .local()
                                    .format(dateFormat),
                        },
                    ]}
                    options={{
                        pageSize: pageSize,
                    }}
                    components={
                        {
                            Toolbar: () => (
                                <div>
                                    <CardHeader title="Deployments" />
                                    <Grid container className={classes.filters}>
                                        <Grid item >
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    ref={searchRef}
                                                    id="search"
                                                    label="Search"
                                                    value={search}
                                                    onChange={handleChangeSearch}
                                                    autoFocus
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item >
                                            <FormControl className={classes.formControl}>
                                                <InputLabel htmlFor="stat">
                                                    Status
                                                </InputLabel>
                                                <Select
                                                    value={status}
                                                    onChange={handleChangeStatus}
                                                    inputProps={{
                                                        name: 'status',
                                                        id: 'stat',
                                                    }}
                                                >
                                                    <MenuItem value="active">
                                                        Active
                                                    </MenuItem>
                                                    <MenuItem value="inactive">
                                                        Inactive
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl className={classes.formControl} disabled={users.length <= 0}>
                                                <InputLabel htmlFor="user">
                                                    Created By
                                                </InputLabel>
                                                <Select
                                                    value={user}
                                                    onChange={handleChangeUser}
                                                    inputProps={{
                                                        name: 'CreatedBy',
                                                        id: 'user',
                                                    }}
                                                >
                                                    {users.map(element => (
                                                        <MenuItem value={element}>
                                                            {element}
                                                        </MenuItem>)
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={classes.formControl}
                                                disabled={search === '' && status === '' && user === ''}
                                                onClick={handleClearFilters}
                                            >
                                                Clear
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>)
                        }
                    }
                    data={query => new Promise((resolve, reject) => {
                        let pageS = 20;
                        let page = 0;
                        let sort = 'createdat';
                        let order = 'desc';
                        let find = search;
                        let stat = status;
                        let createBy = user;
                        if (first) {
                            setFirst(false);
                            if (props.history.location.search !== '') {
                                var urlQuery = Object.fromEntries(
                                    new URLSearchParams(
                                        props.history.location.search.substring(1),
                                    ),
                                );
                                setPagesize(Number(urlQuery.size));
                                setStatus(urlQuery.status);
                                setUser(urlQuery.user);
                                setSearch(urlQuery.search);
                                createBy = urlQuery.user;
                                stat = urlQuery.status;
                                pageS = Number(urlQuery.size);
                                page = Number(urlQuery.page);
                                sort = urlQuery.sort;
                                order = urlQuery.order;
                                find = urlQuery.search;
                            }
                        } else {
                            setPagesize(query.pageSize);
                            pageS = query.pageSize;
                            page = query.page;
                            if (query.orderBy) {
                                sort = query.orderBy.field.toLowerCase();
                                order = query.orderDirection;

                            }
                        }
                        props.history.push(
                            `${props.history.location.pathname}?size=${pageS}&page=${page}&sort=${sort}&order=${order}&search=${find}&status=${status}&user=${createBy}`,
                        );
                        api.getDeploys(pageS, page, sort, order, stat, search, createBy)
                            .then(data => {
                                resolve(
                                    {
                                        data: data.deploys,
                                        page: query.page,
                                        totalCount: data.total,
                                    }
                                );
                            })
                            .catch(err => {
                                if (err.status === 401) {
                                    props.history.push(
                                        `/login?ref=${props.location.pathname}`,
                                    );
                                }
                                reject(err);
                            });
                    })}
                />
            </div>
        </div>
    );
}
Deployments.propTypes = {
    props: PropTypes.object,
};

export default withRouter(Deployments);
