import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Typography,
    Link,
    Grid,
} from '@material-ui/core';

import moment from 'moment';

const dateFormat = 'YYYY-MM-DD hh:mm a';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    cardAttr: {
        padding: theme.spacing(1),
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const CommitCard = props => {
    const commit = props.commit;

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <form>
                <CardHeader title="Last Commit Info" />
                <Divider />
                <CardContent>
                    <div className={classes.cardAttr}>
                        <Typography component="h6" variant="h6">
                            {commit.branchURL ? (
                                <Link
                                    href={commit.branchURL}
                                    className={classes.link}
                                    target="_blank"
                                >
                                    {commit.branchURL}
                                </Link>
                            ) : (
                                ' --- '
                            )}
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                            Branch Url
                        </Typography>
                    </div>
                    <div className={classes.cardAttr}>
                        <Typography component="h6" variant="h6">
                            {commit.message ? commit.message : ' --- '}
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                            Commit Message
                        </Typography>
                    </div>
                    <Grid container>
                        <Grid item>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {commit.authorName
                                        ? commit.authorName
                                        : ' --- '}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Commit Author
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className={classes.cardAttr}>
                                <Typography component="h6" variant="h6">
                                    {commit.date
                                        ? moment
                                              .utc(commit.date)
                                              .local()
                                              .format(dateFormat)
                                        : ' --- '}
                                </Typography>
                                <Typography
                                    variant="overline"
                                    color="textSecondary"
                                >
                                    Commit Date
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </form>
        </Card>
    );
};

CommitCard.propTypes = {
    commit: PropTypes.object.isRequired,
};

export default CommitCard;
