import React from 'react';
import { useState, useEffect } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    AppBar,
    Toolbar,
    Hidden,
    IconButton,
    Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { Api } from '../../../../common/Api';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
    },
    flexGrow: {
        flexGrow: 1,
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
    },
    whiteText: {
        color: theme.palette.white,
    },
}));

const Topbar = props => {
    const { className, onSidebarOpen, ...rest } = props;

    const classes = useStyles();

    const [username, setUsername] = useState('user');

    useEffect(() => {
        if (username === 'user') {
            new Api()
                .getUser()
                .then(res => {
                    setUsername(res.username);
                })
                .catch();
        }
    });

    return (
        <AppBar {...rest} className={clsx(classes.root, className)}>
            <Toolbar>
                <Typography variant="h1" className={classes.whiteText}>
                    Kodeployer
                </Typography>
                <div className={classes.flexGrow} />
                <Typography variant="h6" className={classes.whiteText}>
                    {username}
                </Typography>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onSidebarOpen}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
};

export default Topbar;
