import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { Api } from '../../common/Api';
import SwipeableViews from 'react-swipeable-views';
import {
    LinearProgress,
    Grid,
    Card,
    Typography,
    CardHeader,
    IconButton,
    AppBar,
    Tabs,
    Tab,
    Divider,
    CardContent,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    AWSCard,
    JenkinsCard,
    CommitCard,
    InfoCard,
    TerminalCard,
    DetailCard,
    HistoryCard,
    AWSTaskCard,
    LogCard,
} from './components';
import TabPanel from '../../components/TabPanel/TabPanel';
import { DecodeXML, EscapeXML } from '../../common/validators';
import { MsgSnackbar } from '../../components';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    rootLoad: {
        flexGrow: 1,
    },
    titleCard: {
        padding: theme.spacing(2),
    },
    paper: {
        height: 140,
        width: 100,
    },
    jenkinsOutput: {
        whiteSpace: 'pre-line',
    },
}));

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const TYPE_TASK = 'task';

const ManageDeploy = props => {
    const classes = useStyles();
    const theme = useTheme();
    const api = new Api();
    const [loading, setLoading] = useState(true);
    const [deploy, setDeploy] = useState(null);
    const [value, setValue] = React.useState(0);
    const [setting, setSetting] = React.useState('');
    const [tags, setTags] = React.useState([]);
    const [showMsg, setShowMsg] = useState(false);
    const [error, setError] = useState(null);
    const [env, setEnv] = useState('development');
    const [addCustom, setAddCustom] = useState(true);
    const [keepAlive, setKeepAlive] = useState(false);
    const bottomRef = useRef(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView();
    };
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    const handleChangeIndex = index => {
        setValue(index);
    };
    const handleChangeSetting = event => {
        setSetting(event.target.value);
    };
    const handleChangeEnv = event => {
        setEnv(event.target.value);
    };
    const handleChangeCustom = event => {
        setAddCustom(true);
    };
    const handleChangeKeep = event => {
        setKeepAlive(!keepAlive);
    };
    useEffect(() => {
        if (deploy === null) {
            api.getDeploy(props.match.params.id)
                .then(data => {
                    setDeploy(data);
                    if (data.tags) {
                        let aux = [];
                        data.tags.forEach((elem, index, arr) => {
                            aux.push({ key: index, label: elem });
                        });
                        setTags(aux);
                    }
                    setSetting(DecodeXML(data.setting));
                    setAddCustom(data.addCustomData ?? false);
                    setKeepAlive(data.keepAlive ?? false);
                    setEnv(data.env ?? 'development');
                    setLoading(false);
                    setError(null);
                })
                .catch(err => {
                    if (err.status === 401) {
                        props.history.push(
                            '/login?ref=' + props.location.pathname,
                        );
                    }
                    setError(err.error);
                    setShowMsg(true);
                });
        }
    }, [api, deploy, props]);
    const handleErr = err => {
        if (err.status === 401) {
            props.history.push('/login?ref=' + props.location.pathname);
        }
        setError(err.error);
        setShowMsg(true);
        setLoading(false);
    };
    const handleRefresh = event => {
        setLoading(true);
        setDeploy(null);
    };
    const handleSuccess = data => {
        setError(null);
        setShowMsg(true);
        setDeploy(null);
    };
    const handleCreateJob = event => {
        setLoading(true);
        api.createJob(props.match.params.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    };
    const handleJobBuild = event => {
        setLoading(true);
        api.createJobBuild(props.match.params.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    };
    const handleDelJob = event => {
        setLoading(true);
        api.deleteJob(props.match.params.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    };
    const handleCreateInstance = event => {
        setLoading(true);
        api.createInstance(props.match.params.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    };
    const handleDelInstance = event => {
        setLoading(true);
        api.deleteInstance(props.match.params.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    };
    const handleEnableSSL = event => {
        setLoading(true);
        api.enableSSL(props.match.params.id)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    };
    const handleUpdateDeploy = event => {
        setLoading(true);
        const escaped = EscapeXML(setting);
        let aux = [];
        tags.forEach(elem => {
            aux.push(elem.label);
        });
        api.updateDeploy(props.match.params.id, escaped, aux, env, addCustom, keepAlive)
            .then(data => handleSuccess(data))
            .catch(err => handleErr(err));
    };
    if (loading || deploy === null) {
        return (
            <div className={classes.rootLoad}>
                <MsgSnackbar
                    open={showMsg}
                    setOpen={setShowMsg}
                    msg={error}
                ></MsgSnackbar>
                <LinearProgress />
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                <MsgSnackbar
                    open={showMsg}
                    setOpen={setShowMsg}
                    msg={error}
                ></MsgSnackbar>
                <div className={classes.content}>
                    <Card className={classes.card} elevation={3}>
                        <CardHeader
                            title={
                                <div className={classes.titleCard}>
                                    <Grid container>
                                        <Grid
                                            className={classes.statsItem}
                                            item
                                            sm={8}
                                            xs={12}
                                        >
                                            <Typography
                                                component="h3"
                                                variant="h3"
                                            >
                                                {deploy.deployName}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textSecondary"
                                            >
                                                Deployment
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            className={classes.statsItem}
                                            item
                                            xs={2}
                                        >
                                            <Typography
                                                component="h5"
                                                variant="h5"
                                            >
                                                {'~$' +
                                                    Number.parseFloat(
                                                        deploy.currentCost,
                                                    ).toFixed(4)}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textSecondary"
                                            >
                                                Current Cost
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            className={classes.statsItem}
                                            item
                                            xs={2}
                                        >
                                            <Typography
                                                component="h5"
                                                variant="h5"
                                            >
                                                {'~$' +
                                                    Number.parseFloat(
                                                        deploy.totalCost,
                                                    ).toFixed(4)}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textSecondary"
                                            >
                                                Total Cost
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                            action={
                                <Grid>
                                    <IconButton
                                        aria-label="Go to bottom"
                                        onClick={scrollToBottom}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={handleRefresh}
                                    >
                                        <RefreshIcon />
                                    </IconButton>
                                </Grid>
                            }
                        ></CardHeader>
                    </Card>
                    <div>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label="Details" {...a11yProps(0)} />
                                <Tab label="Config" {...a11yProps(1)} />
                                <Tab label="Manage" {...a11yProps(2)} />
                                <Tab label={deploy.type === TYPE_TASK ? 'Logs' : 'Terminal'} {...a11yProps(3)} />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel
                                value={value}
                                index={0}
                                dir={theme.direction}
                            >
                                <Grid container alignItems="center" spacing={4}>
                                    <Grid item lg={6} md={6} xl={6} xs={12}>
                                        <DetailCard
                                            deploy={deploy}
                                        ></DetailCard>
                                    </Grid>
                                    {/* <Grid item lg={6} md={6} xl={6} xs={12}>
                                        <CommitCard
                                            commit={deploy.lastCommit}
                                        ></CommitCard>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        {deploy.history ? (
                                            <HistoryCard
                                                changes={deploy.history}
                                            ></HistoryCard>
                                        ) : (
                                            <div></div>
                                        )}
                                    </Grid>
                                </Grid>
                                <div className={classes.content}></div>
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index={1}
                                dir={theme.direction}
                            >
                                <InfoCard
                                    setting={setting}
                                    env={env}
                                    addCustom={addCustom}
                                    keepAlive={keepAlive}
                                    handleChangeEnv={handleChangeEnv}
                                    handleChangeCustom={handleChangeCustom}
                                    handleUpdate={handleUpdateDeploy}
                                    handleChangeSetting={handleChangeSetting}
                                    handleChangeKeep={handleChangeKeep}
                                    tags={tags}
                                    setTags={setTags}
                                ></InfoCard>
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index={2}
                                dir={theme.direction}
                            >
                                <Grid container alignItems="center" spacing={4}>
                                    {deploy.type !== TYPE_TASK && (<Grid item lg={6} md={6} xl={6} xs={12}>
                                        <AWSCard
                                            deploy={deploy}
                                            handleCreate={handleCreateInstance}
                                            handleDelete={handleDelInstance}
                                            handleSSL={handleEnableSSL}
                                        ></AWSCard>
                                    </Grid>)}
                                    {deploy.type === TYPE_TASK && (<Grid item lg={6} md={6} xl={6} xs={12}>
                                        <AWSTaskCard
                                            deploy={deploy}
                                            handleRefresh={handleRefresh}
                                        ></AWSTaskCard>
                                    </Grid>)}
                                    <Grid item lg={6} md={6} xl={6} xs={12}>
                                        <JenkinsCard
                                            deploy={deploy}
                                            handleCreate={handleCreateJob}
                                            handleBuild={handleJobBuild}
                                            handleDelete={handleDelJob}
                                            handleScroll={scrollToBottom}
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} xl={12} xs={12}>
                                        <Card className={classes.root}>
                                            <CardHeader title="Jenkins Last Build Output" />
                                            <Divider />
                                            <CardContent>
                                                <div
                                                    className={
                                                        classes.jenkinsOutput
                                                    }
                                                >
                                                    {deploy.jenkinsOutput}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index={3}
                                dir={theme.direction}
                            >
                                {deploy.type === TYPE_TASK && (<LogCard deploy={deploy}></LogCard>)}
                                {deploy.type !== TYPE_TASK && (<TerminalCard deploy={deploy}></TerminalCard>)}
                            </TabPanel>

                        </SwipeableViews>
                    </div>
                    <div ref={bottomRef}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <IconButton
                                    aria-label="Go up"
                                    onClick={scrollToTop}
                                >
                                    <ExpandLessIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div >
        );
    }
};

ManageDeploy.propTypes = {
    props: PropTypes.object,
};

export default withRouter(ManageDeploy);
