import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Button,
    Grid,
    TextField,
    LinearProgress,
} from '@material-ui/core';
import { Api } from '../../../../common/Api';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    cardAttr: {
        padding: theme.spacing(2),
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
    },
    consoleOutput: {
        whiteSpace: 'pre-line',
    },
}));

const TerminalCard = props => {
    const deploy = props.deploy;

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [script, setScript] = useState('');
    const [result, setResult] = useState('');

    const handleExecuteCommand = async event => {
        setLoading(true);
        const api = new Api();

        api.executeCommand(deploy.id, script)
            .then(data => {
                setResult(data.msg);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            });
    };
    const handleChange = event => {
        setScript(event.target.value);
    };

    return (
        <Card className={classes.root}>
            <CardHeader title="Command Execution" />
            <Divider />
            <CardContent>
                <Grid container alignItems="center" spacing={4}>
                    <Grid item lg={8} md={8} xl={8} xs={8}>
                        <div className={classes.cardAttr}>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                rowsMax="10"
                                value={script}
                                className={classes.root}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                helperText="Scripts to Execute"
                                variant="outlined"
                            />
                        </div>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={4}>
                        <div className={classes.cardAttr}>
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={
                                    deploy.awsState !== 'running' || loading
                                }
                                onClick={handleExecuteCommand}
                            >
                                Execute Command
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
            <Divider />
            <CardHeader title="Command Result" />
            {loading ? (
                <div className={classes.rootLoad}>
                    <LinearProgress />
                </div>
            ) : (
                <div className={classes.cardAttr}>
                    <div className={classes.consoleOutput}>{result}</div>
                </div>
            )}
        </Card>
    );
};

TerminalCard.propTypes = {
    deploy: PropTypes.object.isRequired,
};

export default TerminalCard;
