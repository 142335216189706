import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Grid,
    Divider,
    Button,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    imageContainer: {
        height: 64,
        width: 64,
        margin: '0 auto',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: '100%',
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
    },
    statsIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(1),
    },
}));

const ProjectCard = props => {
    const { className, project, ...rest } = props;

    const classes = useStyles();

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
            raised={true}
            elevation={2}
        >
            <CardContent>
                <div className={classes.imageContainer}>
                    <img
                        alt="Project"
                        className={classes.image}
                        src={'/images/lang/' + project.image}
                    />
                </div>
                <Typography align="center" gutterBottom variant="h4">
                    {project.name}
                </Typography>
            </CardContent>
            <Divider />
            <CardActions>
                <Grid container justify="space-between">
                    <Grid className={classes.statsItem} item>
                        <RouterLink to={'/create-deploy/' + project.id}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                            >
                                Create Deploy
                            </Button>
                        </RouterLink>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

ProjectCard.propTypes = {
    className: PropTypes.string,
    project: PropTypes.object.isRequired,
};

export default ProjectCard;
