const API_URL = process.env.REACT_APP_API_URL;
export const URL = {
    LOGIN: API_URL + '/login',
    GETPROJECTS: API_URL + '/projects',
    GETPROJECT: API_URL + '/projects/~id~',
    GETEDITPROJECT: API_URL + '/projects/~id~/edit',
    GETDASHBOARD: API_URL + '/dashboard',
    GETBRANCHES: API_URL + '/projects/~id~/branches',
    DEPLOYS: API_URL + '/deploys',
    GETDEPLOY: API_URL + '/deploys/~id~',
    DEPLOYJOB: API_URL + '/deploys/~id~/job',
    DEPLOYBUILD: API_URL + '/deploys/~id~/build',
    DEPLOYINSTANCE: API_URL + '/deploys/~id~/instance',
    DEPLOYCOMMAND: API_URL + '/deploys/~id~/command',
    DEPLOYSSL: API_URL + '/deploys/~id~/ssl',
    PASSWORD: API_URL + '/password',
    GETUSER: API_URL + '/profile',
    USERS: API_URL + '/users',
    TASKS: API_URL + '/tasks',
    TASKSDETAIL: API_URL + '/tasks/~id~',
    TASKSSL: API_URL + '/tasks/~id~/ssl',
};

export class Api {
    authHeader = { Authorization: 'Bearer ' + localStorage.getItem('token') };
    getOption = {
        method: 'GET',
        headers: this.authHeader,
    };
    postOption = {
        method: 'POST',
        headers: this.authHeader,
    };
    delOption = {
        method: 'DELETE',
        headers: this.authHeader,
    };

    async returnResp(res) {
        if (!res.ok) {
            let error = await res.json();
            return Promise.reject(error);
        }
        const data = await res.json();
        return data;
    }
    async loginUser(user, pass) {
        const loginReq = {
            username: user,
            password: pass,
        };
        const options = {
            method: 'POST',
            body: JSON.stringify(loginReq),
        };
        const res = await fetch(URL.LOGIN, options);
        return this.returnResp(res);
    }

    async getProjects() {
        const res = await fetch(URL.GETPROJECTS, this.getOption);
        return this.returnResp(res);
    }
    async getDeploys(size, page, sort, order, status, search, user) {
        let url = `${URL.DEPLOYS}?size=${size}&page=${page}&sort=${sort}&order=${order}&status=${status}&search=${search}&user=${user}`;
        const res = await fetch(url, this.getOption);
        return this.returnResp(res);
    }
    async getDashboard() {
        const res = await fetch(URL.GETDASHBOARD, this.getOption);
        return this.returnResp(res);
    }
    async getUsers() {
        const res = await fetch(URL.USERS, this.getOption);
        return this.returnResp(res);
    }
    async getProjectBranches(id) {
        let url = URL.GETBRANCHES;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.getOption);
        return this.returnResp(res);
    }
    async getProject(id) {
        let url = URL.GETPROJECT;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.getOption);
        return this.returnResp(res);
    }
    async getEditProject(id) {
        let url = URL.GETEDITPROJECT;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.getOption);
        return this.returnResp(res);
    }
    async createDeploy(projectID, codename, branch, setting, env, custom, keepAlive, onlyRecord, type) {
        const deployReq = {
            codeName: codename,
            projectID: projectID,
            branch: branch,
            settings: setting,
            env,
            addCustom: custom,
            keepAlive,
            onlyRecord,
            type,
        };
        const url = type === "task" ? URL.TASKS : URL.DEPLOYS
        const options = {
            method: 'POST',
            headers: this.authHeader,
            body: JSON.stringify(deployReq),
        };
        const res = await fetch(url, options);
        return this.returnResp(res);
    }
    async getDeploy(id) {
        let url = URL.GETDEPLOY;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.getOption);
        return this.returnResp(res);
    }
    async createTaskDeploy(id) {
        let url = URL.TASKSDETAIL;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.postOption);
        return this.returnResp(res);
    }
    async deleteTaskDeploy(id) {
        let url = URL.TASKSDETAIL;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.delOption);
        return this.returnResp(res);
    }
    async enableTaskSSL(id) {
        let url = URL.TASKSSL;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.postOption);
        return this.returnResp(res);
    }
    async createJob(id) {
        let url = URL.DEPLOYJOB;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.postOption);
        return this.returnResp(res);
    }
    async createJobBuild(id) {
        let url = URL.DEPLOYBUILD;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.postOption);
        return this.returnResp(res);
    }
    async deleteJob(id) {
        let url = URL.DEPLOYJOB;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.delOption);
        return this.returnResp(res);
    }
    async createInstance(id) {
        let url = URL.DEPLOYINSTANCE;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.postOption);
        return this.returnResp(res);
    }
    async deleteInstance(id) {
        let url = URL.DEPLOYINSTANCE;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.delOption);
        return this.returnResp(res);
    }
    async enableSSL(id) {
        let url = URL.DEPLOYSSL;
        url = url.replace('~id~', id);
        const res = await fetch(url, this.postOption);
        return this.returnResp(res);
    }

    async executeCommand(id, command) {
        let url = URL.DEPLOYCOMMAND;
        url = url.replace('~id~', id);
        const executeReq = {
            command: command,
        };
        const options = {
            method: 'POST',
            headers: this.authHeader,
            body: JSON.stringify(executeReq),
        };
        const res = await fetch(url, options);
        return this.returnResp(res);
    }
    async updateDeploy(id, setting, tags, env, custom, keepAlive) {
        let url = URL.GETDEPLOY;
        url = url.replace('~id~', id);
        const deployReq = {
            settings: setting,
            tags: tags,
            env,
            addCustom: custom,
            keepAlive,
        };
        const options = {
            method: 'POST',
            headers: this.authHeader,
            body: JSON.stringify(deployReq),
        };
        const res = await fetch(url, options);
        return this.returnResp(res);
    }
    async updateProject(id, project) {
        let url = URL.GETPROJECT;
        url = url.replace('~id~', id);
        const options = {
            method: 'POST',
            headers: this.authHeader,
            body: JSON.stringify(project),
        };
        const res = await fetch(url, options);
        return this.returnResp(res);
    }
    async updatePassword(password) {
        let url = URL.PASSWORD;
        const reqBody = {
            password: password,
        };
        const options = {
            method: 'POST',
            headers: this.authHeader,
            body: JSON.stringify(reqBody),
        };
        const res = await fetch(url, options);
        return this.returnResp(res);
    }
    async getUser() {
        let url = URL.GETUSER;
        const res = await fetch(url, this.getOption);
        return this.returnResp(res);
    }
}
