import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
    SignIn as SignInView,
    NotFound as NotFoundView,
    Dashboard as DashboardView,
    Account as AccountView,
    CreateDeploy as CreateDeployView,
    ManageDeploy as ManageDeployView,
    EditProject as EditProjectView,
    Deployments as DeployView,
} from './views';

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <RouteWithLayout
                component={DashboardView}
                exact
                layout={MainLayout}
                path="/dashboard"
            />
            <RouteWithLayout
                component={DeployView}
                exact
                layout={MainLayout}
                path="/deployments"
            />
            <RouteWithLayout
                component={CreateDeployView}
                exact
                layout={MainLayout}
                path="/create-deploy/:id"
            />
            <RouteWithLayout
                component={EditProjectView}
                exact
                layout={MainLayout}
                path="/edit-project/:id"
            />
            <RouteWithLayout
                component={ManageDeployView}
                exact
                layout={MainLayout}
                path="/manage-deploy/:id"
            />
            <RouteWithLayout
                component={AccountView}
                exact
                layout={MainLayout}
                path="/account"
            />
            <RouteWithLayout
                component={SignInView}
                exact
                layout={MinimalLayout}
                path="/login"
            />
            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path="/not-found"
            />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
