import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Api } from '../../common/Api';
import {
    LinearProgress,
    CardHeader,
    Divider,
    TextField,
    CardContent,
    CardActions,
    Button,
    Card,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '../../components/Autocomplete/Autocomplete';
import { EscapeXML } from '../../common/validators';
import { MsgSnackbar } from '../../components';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    rootLoad: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const CreateDeploy = props => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [branch, setBranch] = useState({});
    const [setting, setSetting] = useState('');
    const [project, setProject] = useState(null);
    const [codename, setCodeName] = useState('');
    const [env, setEnv] = useState('');
    const [showMsg, setShowMsg] = useState(false);
    const [error, setError] = useState(null);
    const [addCustom, setAddCustom] = useState(true);
    const [keepAlive, setKeepAlive] = useState(false);
    const [onlyRecord, setOnlyRecord] = useState(false);

    useEffect(() => {
        const api = new Api();
        if (project === null) {
            api.getProject(props.match.params.id)
                .then(data => {
                    setProject(data);
                    setLoading(false);
                    setError(null);
                })
                .catch(err => {
                    if (err.status === 401) {
                        props.history.push(
                            '/login?ref=' + props.location.pathname,
                        );
                    }
                    setError(err.error);
                    setShowMsg(true);
                });
        }
    }, [project, props]);

    const handleChangeSelect = value => {
        setBranch(value);
        let name = project.name + '-' + value.value;
        setCodeName(name.toLowerCase());
    };
    const handleChangeSetting = event => {
        setSetting(event.target.value);
    };
    const handleNameChange = event => {
        setCodeName(event.target.value.replace(/ /g, '').toLowerCase());
    };
    const handleChangeCustom = event => {
        setAddCustom(true);
    };
    const handleChangeKeep = event => {
        setKeepAlive(!keepAlive);
    };
    const handleChangeOnlyRecord = event => {
        setOnlyRecord(!onlyRecord);
    };
    const handleChangeENV = event => {
        var enValue = event.target.value;
        setEnv(enValue);
        setSetting(project[enValue]);
    };

    const handleCreateDeploy = async event => {
        setLoading(true);
        const api = new Api();
        var escapeSett = EscapeXML(setting);
        api.createDeploy(
            props.match.params.id,
            codename,
            branch.value,
            escapeSett,
            env,
            addCustom,
            keepAlive,
            onlyRecord,
            project.type,
        )
            .then(data => {
                setError(null);
                setShowMsg(true);
                props.history.push(`/manage-deploy/${data.msg}`);
            })
            .catch(err => {
                if (err.status === 401) {
                    props.history.push('/login?ref=' + props.location.pathname);
                }
                setError(err.error);
                setShowMsg(true);
                setLoading(false);
            });
    };

    if (loading || project === null) {
        return (
            <div className={classes.rootLoad}>
                <MsgSnackbar
                    open={showMsg}
                    setOpen={setShowMsg}
                    msg={error}
                ></MsgSnackbar>
                <LinearProgress />
            </div>
        );
    } else {
        return (
            <div className={classes.root}>
                <MsgSnackbar
                    open={showMsg}
                    setOpen={setShowMsg}
                    msg={error}
                ></MsgSnackbar>
                <div className={classes.content}>
                    <Card className={classes.card} elevation={3}>
                        <CardHeader
                            title={
                                <div className={classes.titleCard}>
                                    <Grid container justify="space-between">
                                        <Grid
                                            className={classes.statsItem}
                                            item
                                        >
                                            <Typography
                                                component="h3"
                                                variant="h3"
                                            >
                                                {project.name}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="textSecondary"
                                            >
                                                Project
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        ></CardHeader>
                    </Card>
                    <form>
                        <CardHeader subheader="Fill out the required data" />
                        <CardContent>
                            <div className={classes.content}>
                                <Autocomplete
                                    label={'Select a Branch'}
                                    suggestions={project.branches}
                                    value={branch}
                                    handleChange={handleChangeSelect}
                                    className={classes.formControl}
                                />
                            </div>
                            <TextField
                                fullWidth
                                required
                                label="Deployment Name"
                                name="codename"
                                type="text"
                                value={codename}
                                onChange={handleNameChange}
                                className={classes.formControl}
                                inputProps={{
                                    maxLength: '50',
                                }}
                            />
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="env">
                                            Environment
                                        </InputLabel>
                                        <Select
                                            value={env}
                                            onChange={handleChangeENV}
                                            inputProps={{
                                                name: 'environemnt',
                                                id: 'env',
                                            }}
                                        >
                                            <MenuItem value={'development'}>
                                                Development
                                            </MenuItem>
                                            <MenuItem value={'test-dev'}>
                                                Test-Dev
                                            </MenuItem>
                                            <MenuItem value={'staging'}>
                                                Staging
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {(project.type !== 'task') && (
                                    <Grid item container xs={6}>
                                        <Grid item xs={3}>
                                            <FormControlLabel className={classes.formControl}
                                                control={<Switch checked={onlyRecord} onChange={handleChangeOnlyRecord} name="without" />}
                                                label="Create Only Record"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControlLabel className={classes.formControl}
                                                control={<Switch checked={keepAlive} onChange={handleChangeKeep} name="keep" />}
                                                label="Keep Instance Alive"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {(project.extra) ? <Grid item xs={3}>
                                    <FormControlLabel className={classes.formControl}
                                        control={<Switch checked={addCustom} onChange={handleChangeCustom} name="custom" disabled />}
                                        label="Use Remote Mongo"
                                    />
                                </Grid>
                                    : <div></div>}

                            </Grid>
                            <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                rowsMax="10"
                                value={setting}
                                className={classes.formControl}
                                onChange={handleChangeSetting}
                                fullWidth
                                margin="normal"
                                helperText="Application Settings"
                                variant="outlined"
                            />
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={handleCreateDeploy}
                            >
                                Create Deploy
                            </Button>
                        </CardActions>
                    </form>
                </div>
            </div>
        );
    }
};

CreateDeploy.propTypes = {
    props: PropTypes.object,
};

export default withRouter(CreateDeploy);
